import React from 'react';
import { motion } from 'framer-motion';
import SEO from '../components/common/SEO';
import { 
  FiUsers, 
  FiTarget, 
  FiAward,
  FiTrendingUp,
  FiGlobe,
  FiCpu
} from 'react-icons/fi';

const values = [
  {
    icon: <FiUsers />,
    title: "Human-Centered AI",
    description: "We believe AI should enhance human capabilities, not replace them. Our digital workforce empowers people to focus on what matters most."
  },
  {
    icon: <FiTarget />,
    title: "Outcome-Driven",
    description: "We measure success by the real-world impact our solutions create for businesses and their customers."
  },
  {
    icon: <FiAward />,
    title: "Excellence",
    description: "We maintain the highest standards in AI safety, reliability, and performance to ensure our digital workforce delivers consistent results."
  }
];

const stats = [
  { number: "5min", label: "Average Setup Time" },
  { number: "80%", label: "Cost Reduction" },
  { number: "24/7", label: "Digital Workforce Availability" },
  { number: "99.9%", label: "System Reliability" }
];

const About = () => {
  return (
    <>
      <SEO 
        title="About Us"
        description="Learn about Fîscus's mission to revolutionize business operations with AI-powered digital workforce solutions."
      />
      
      {/* Hero Section */}
      <section className="py-20 bg-brand-sand/10">
        <div className="container mx-auto max-w-screen-xl px-6">
          <div className="text-center mb-16">
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-4xl md:text-5xl font-bold text-brand-burgundy mb-4"
            >
              Empowering Businesses with Digital Workforce Solutions
            </motion.h1>
            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-xl text-gray-600 max-w-2xl mx-auto"
            >
              We're building the future of work by making AI-powered digital workers accessible to businesses of all sizes.
            </motion.p>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="py-20">
        <div className="container mx-auto max-w-screen-xl px-6">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl font-bold text-brand-burgundy mb-6">Our Mission</h2>
              <p className="text-gray-600 mb-6">
                At Fîscus, we believe every business deserves access to cutting-edge AI technology. 
                Our mission is to democratize digital workforce solutions, making them accessible, 
                affordable, and easy to implement for businesses of all sizes.
              </p>
              <p className="text-gray-600">
                We're not just building AI tools; we're creating digital teammates that understand 
                your business needs and work tirelessly to help you achieve your goals.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="grid grid-cols-2 gap-6"
            >
              {stats.map((stat, index) => (
                <div 
                  key={index}
                  className="bg-white p-6 rounded-xl shadow-lg text-center"
                >
                  <div className="text-3xl font-bold text-brand-rose mb-2">
                    {stat.number}
                  </div>
                  <div className="text-gray-600">
                    {stat.label}
                  </div>
                </div>
              ))}
            </motion.div>
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-20 bg-brand-sand/10">
        <div className="container mx-auto max-w-screen-xl px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-brand-burgundy mb-4">Our Values</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              These core principles guide everything we do at Fîscus.
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {values.map((value, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-8 rounded-xl shadow-lg"
              >
                <div className="text-3xl text-brand-rose mb-4">
                  {value.icon}
                </div>
                <h3 className="text-xl font-bold text-brand-burgundy mb-3">
                  {value.title}
                </h3>
                <p className="text-gray-600">
                  {value.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Future Vision Section */}
      <section className="py-20">
        <div className="container mx-auto max-w-screen-xl px-6">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="order-2 md:order-1"
            >
              <div className="grid grid-cols-2 gap-6">
                <div className="bg-white p-6 rounded-xl shadow-lg text-center">
                  <FiGlobe className="text-4xl text-brand-rose mx-auto mb-3" />
                  <h3 className="text-lg font-bold text-brand-burgundy mb-2">Global Impact</h3>
                  <p className="text-gray-600 text-sm">Empowering businesses worldwide</p>
                </div>
                <div className="bg-white p-6 rounded-xl shadow-lg text-center">
                  <FiCpu className="text-4xl text-brand-rose mx-auto mb-3" />
                  <h3 className="text-lg font-bold text-brand-burgundy mb-2">AI Innovation</h3>
                  <p className="text-gray-600 text-sm">Pushing technological boundaries</p>
                </div>
                <div className="bg-white p-6 rounded-xl shadow-lg text-center">
                  <FiTrendingUp className="text-4xl text-brand-rose mx-auto mb-3" />
                  <h3 className="text-lg font-bold text-brand-burgundy mb-2">Sustainable Growth</h3>
                  <p className="text-gray-600 text-sm">Building lasting solutions</p>
                </div>
                <div className="bg-white p-6 rounded-xl shadow-lg text-center">
                  <FiUsers className="text-4xl text-brand-rose mx-auto mb-3" />
                  <h3 className="text-lg font-bold text-brand-burgundy mb-2">Community Focus</h3>
                  <p className="text-gray-600 text-sm">Growing together</p>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="order-1 md:order-2"
            >
              <h2 className="text-3xl font-bold text-brand-burgundy mb-6">Our Vision for the Future</h2>
              <p className="text-gray-600 mb-6">
                We envision a future where every business has access to their own digital workforce, 
                breaking down the barriers between human creativity and AI capabilities. Our platform 
                is just the beginning of this transformation.
              </p>
              <p className="text-gray-600">
                By continuing to innovate and expand our digital workforce solutions, we're working 
                to create a world where businesses can focus on what truly matters: growth, 
                innovation, and delivering value to their customers.
              </p>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
