import React, { useState } from 'react';
import { motion } from 'framer-motion';
import SEO from '../components/common/SEO';
import { 
  FiMail, 
  FiMessageSquare, 
  FiClock,
  FiCheckCircle,
  FiAlertCircle
} from 'react-icons/fi';

const faqs = [
  {
    question: "How quickly can I set up my digital workforce?",
    answer: "Most integrations can be set up in under 5 minutes. Our AI-powered platform handles the complex configuration automatically, allowing you to focus on your business goals."
  },
  {
    question: "What kind of support do you provide?",
    answer: "We offer 24/7 technical support, comprehensive documentation, and dedicated account managers for enterprise clients. Our team is always ready to help you succeed."
  },
  {
    question: "Is my data secure?",
    answer: "Yes, we implement enterprise-grade security measures including encryption, regular security audits, and compliance with major data protection regulations."
  },
  {
    question: "Can I customize the digital workforce for my specific needs?",
    answer: "Absolutely! Our platform is highly customizable and can be tailored to your specific business processes and requirements."
  }
];

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });
  const [formStatus, setFormStatus] = useState({
    submitted: false,
    success: false,
    message: ''
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.message.trim()) newErrors.message = 'Message is required';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setFormStatus({ submitted: true, success: false, message: 'Sending...' });

    // Simulate API call
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      setFormStatus({
        submitted: true,
        success: true,
        message: "Thank you! We'll be in touch soon."
      });
      setFormData({ name: '', email: '', company: '', message: '' });
    } catch (error) {
      setFormStatus({
        submitted: true,
        success: false,
        message: 'Something went wrong. Please try again.'
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  return (
    <>
      <SEO 
        title="Contact Us"
        description="Get in touch with Fîscus to learn how our digital workforce solutions can transform your business operations."
      />

      {/* Hero Section */}
      <section className="py-20 bg-brand-sand/10">
        <div className="container mx-auto max-w-screen-xl px-6">
          <div className="text-center mb-16">
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-4xl md:text-5xl font-bold text-brand-burgundy mb-4"
            >
              Let's Build Your Digital Workforce
            </motion.h1>
            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-xl text-gray-600 max-w-2xl mx-auto"
            >
              Ready to transform your business? We're here to help you get started.
            </motion.p>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="py-20">
        <div className="container mx-auto max-w-screen-xl px-6">
          <div className="grid md:grid-cols-2 gap-12">
            {/* Contact Form */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="bg-white p-8 rounded-xl shadow-lg"
            >
              <h2 className="text-2xl font-bold text-brand-burgundy mb-6">Get in Touch</h2>
              
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-gray-700 mb-2">Name *</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={`w-full px-4 py-2 rounded-lg border ${
                      errors.name ? 'border-red-500' : 'border-gray-300'
                    } focus:outline-none focus:ring-2 focus:ring-brand-rose`}
                  />
                  {errors.name && (
                    <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="email" className="block text-gray-700 mb-2">Email *</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`w-full px-4 py-2 rounded-lg border ${
                      errors.email ? 'border-red-500' : 'border-gray-300'
                    } focus:outline-none focus:ring-2 focus:ring-brand-rose`}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="company" className="block text-gray-700 mb-2">Company</label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-brand-rose"
                  />
                </div>

                <div>
                  <label htmlFor="message" className="block text-gray-700 mb-2">Message *</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="4"
                    className={`w-full px-4 py-2 rounded-lg border ${
                      errors.message ? 'border-red-500' : 'border-gray-300'
                    } focus:outline-none focus:ring-2 focus:ring-brand-rose`}
                  />
                  {errors.message && (
                    <p className="text-red-500 text-sm mt-1">{errors.message}</p>
                  )}
                </div>

                <button
                  type="submit"
                  className="w-full bg-brand-burgundy text-white py-3 rounded-lg hover:bg-brand-rose transition-colors duration-300"
                  disabled={formStatus.submitted && !formStatus.success}
                >
                  {formStatus.submitted ? (
                    <span className="flex items-center justify-center">
                      {formStatus.success ? (
                        <><FiCheckCircle className="mr-2" /> {formStatus.message}</>
                      ) : (
                        <><FiAlertCircle className="mr-2" /> {formStatus.message}</>
                      )}
                    </span>
                  ) : (
                    'Send Message'
                  )}
                </button>
              </form>
            </motion.div>

            {/* Contact Info & FAQs */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              className="space-y-12"
            >
              {/* Quick Contact */}
              <div className="bg-white p-8 rounded-xl shadow-lg">
                <h2 className="text-2xl font-bold text-brand-burgundy mb-6">Quick Contact</h2>
                <div className="space-y-4">
                  <div className="flex items-center text-gray-600">
                    <FiMail className="text-brand-rose mr-3" />
                    <a href="mailto:hello@fiscus.ai" className="hover:text-brand-rose transition-colors">
                      hello@fiscus.ai
                    </a>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <FiMessageSquare className="text-brand-rose mr-3" />
                    <span>Live chat available</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <FiClock className="text-brand-rose mr-3" />
                    <span>Response time: &lt; 24 hours</span>
                  </div>
                </div>
              </div>

              {/* FAQs */}
              <div className="bg-white p-8 rounded-xl shadow-lg">
                <h2 className="text-2xl font-bold text-brand-burgundy mb-6">Frequently Asked Questions</h2>
                <div className="space-y-6">
                  {faqs.map((faq, index) => (
                    <div key={index}>
                      <h3 className="font-bold text-gray-800 mb-2">{faq.question}</h3>
                      <p className="text-gray-600">{faq.answer}</p>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
