import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/common/SEO';

const NotFound = () => {
  return (
    <>
      <SEO 
        title="Page Not Found"
        description="The page you're looking for doesn't exist."
      />
      <div className="min-h-[60vh] flex flex-col items-center justify-center px-4">
        <h1 className="text-4xl md:text-5xl font-bold text-brand-burgundy mb-4">
          404: Page Not Found
        </h1>
        <p className="text-gray-600 mb-8 text-center max-w-md">
          Oops! The page you're looking for seems to have wandered off. Let's get you back on track.
        </p>
        <Link
          to="/"
          className="bg-brand-burgundy text-white px-6 py-3 rounded-full hover:bg-brand-rose transition-colors duration-300"
        >
          Return Home
        </Link>
      </div>
    </>
  );
};

export default NotFound;
