// src/TermsOfService.js

import React from 'react';

function TermsOfService() {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Terms of Service</h1>
      <p><strong>Last Updated:</strong> November 29, 2024</p>

      <p>
        Welcome to Fiscus Flows, Inc. ("Fiscus," "we," "us," or "our"). These Terms of Service ("Terms") govern your use of our website, services, and any related features, content, or applications provided by us (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Services.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using our Services, you agree to comply with and be legally bound by these Terms and any additional terms and conditions that apply to specific features of the Services. If you are using the Services on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.
      </p>

      <h2>2. Eligibility</h2>
      <p>
        You must be at least 18 years old to use our Services. By using the Services, you represent and warrant that you meet this age requirement and that all information you provide is accurate and complete.
      </p>

      <h2>3. Account Registration</h2>
      <p>
        To access certain features of our Services, you may be required to create an account. You agree to:
      </p>
      <ul>
        <li>Provide accurate, current, and complete information during registration.</li>
        <li>Maintain the security of your password and account credentials.</li>
        <li>Notify us immediately of any unauthorized access to or use of your account.</li>
        <li>Be responsible for all activities that occur under your account.</li>
      </ul>

      <h2>4. Use of Services</h2>
      <p>
        You agree to use the Services only for purposes that are permitted by these Terms and in accordance with all applicable laws and regulations. You are prohibited from using the Services to:
      </p>
      <ul>
        <li>Engage in any unlawful, fraudulent, or malicious activities.</li>
        <li>Transmit any viruses, malware, or other harmful code.</li>
        <li>Attempt to gain unauthorized access to our systems or other user accounts.</li>
        <li>Interfere with the operation of the Services or other users' enjoyment of the Services.</li>
      </ul>

      <h2>5. Intellectual Property</h2>
      <p>
        All content, features, and functionality of the Services, including but not limited to text, graphics, logos, images, and software, are the exclusive property of Fiscus Flows, Inc. and are protected by international copyright, trademark, and other intellectual property laws.
      </p>
      <p>
        You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Services without our prior written consent.
      </p>

      <h2>6. Termination</h2>
      <p>
        We may terminate or suspend your access to the Services immediately, without prior notice or liability, for any reason, including without limitation if you breach the Terms.
      </p>
      <p>
        Upon termination, your right to use the Services will immediately cease. If you wish to terminate your account, you may simply discontinue using the Services.
      </p>

      <h2>7. Disclaimer of Warranties</h2>
      <p>
        The Services are provided on an "AS IS" and "AS AVAILABLE" basis. Fiscus Flows, Inc. disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
      </p>
      <p>
        We do not warrant that the Services will be uninterrupted, secure, or error-free, nor do we make any warranty as to the results that may be obtained from the use of the Services.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by applicable law, Fiscus Flows, Inc. shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
      </p>
      <ul>
        <li>Use or inability to use the Services;</li>
        <li>Unauthorized access to or alteration of your transmissions or data;</li>
        <li>Statements or conduct of any third party on the Services;</li>
        <li>Any other matter relating to the Services.</li>
      </ul>
      <p>
        In no event shall our total liability to you for all damages, losses, and causes of action exceed the amount you have paid us in the last twelve (12) months or, if greater, one hundred dollars ($100).
      </p>

      <h2>9. Indemnification</h2>
      <p>
        You agree to defend, indemnify, and hold harmless Fiscus Flows, Inc., its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Services or your violation of these Terms.
      </p>

      <h2>10. Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws of the State of [Your State], United States, without regard to its conflict of law provisions.
      </p>
      <p>
        Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.
      </p>

      <h2>11. Dispute Resolution</h2>
      <p>
        Any disputes arising out of or related to these Terms and/or the Services shall be resolved through binding arbitration in Delaware, in accordance with the rules of the American Arbitration Association.
      </p>
      <p>
        You agree that any arbitration under these Terms will be conducted on an individual basis and not as a plaintiff or class member in any purported class or representative proceeding.
      </p>

      <h2>12. Changes to the Terms</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least thirty (30) days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
      </p>
      <p>
        By continuing to access or use our Services after any revisions become effective, you agree to be bound by the revised Terms. If you do not agree to the new Terms, you are no longer authorized to use the Services.
      </p>

      <h2>13. Severability</h2>
      <p>
        If any provision of these Terms is found to be unenforceable or invalid by a court, the remaining provisions will remain in full effect and an enforceable term will be substituted reflecting our intent as closely as possible.
      </p>

      <h2>14. Entire Agreement</h2>
      <p>
        These Terms constitute the entire agreement between you and Fiscus Flows, Inc. regarding your use of the Services and supersede all prior agreements and understandings, whether written or oral.
      </p>

      <h2>15. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us:
      </p>
      <ul>
        <li>By email: <a href="mailto:help@fiscusflows.com">help@fiscusflows.com</a></li>
      </ul>
    </div>
  );
}

export default TermsOfService;
