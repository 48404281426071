import React from 'react';
import SEO from '../components/common/SEO';
import { motion } from 'framer-motion';
import { FiDatabase, FiTrendingUp, FiUsers, FiBarChart2 } from 'react-icons/fi';

const products = [
  {
    icon: <FiDatabase />,
    title: "AI Integration Engineer",
    description: "Automate complex integrations in minutes. Connect any tools and create powerful workflows without code.",
    features: [
      "5-minute setup for any integration",
      "AI-powered error handling",
      "Real-time monitoring",
      "Automatic updates"
    ]
  },
  {
    icon: <FiTrendingUp />,
    title: "AI Marketing Manager",
    description: "Your digital marketing expert. Analyze campaigns, optimize content, and drive growth automatically.",
    features: [
      "Campaign performance analysis",
      "Content optimization",
      "Audience targeting",
      "ROI tracking"
    ]
  },
  {
    icon: <FiUsers />,
    title: "AI Database Engineer",
    description: "Manage your data infrastructure effortlessly. Optimize queries, maintain security, and ensure peak performance.",
    features: [
      "Automated optimization",
      "Security monitoring",
      "Backup management",
      "Performance tuning"
    ]
  },
  {
    icon: <FiBarChart2 />,
    title: "AI Accounting Comptroller",
    description: "Your financial operations expert. Automate accounting tasks, manage compliance, and optimize cash flow.",
    features: [
      "Automated bookkeeping",
      "Compliance monitoring",
      "Cash flow optimization",
      "Financial reporting"
    ]
  }
];

const Products = () => {
  return (
    <>
      <SEO 
        title="Products"
        description="Discover our suite of AI-powered digital workforce solutions. From integration to marketing, database management to financial operations."
      />
      
      <section className="py-20 bg-brand-sand/10">
        <div className="container mx-auto max-w-screen-xl px-6">
          <div className="text-center mb-16">
            <h1 className="text-4xl md:text-5xl font-bold text-brand-burgundy mb-4">
              Your Digital Workforce
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Transform your business with AI-powered professionals that work 24/7 to drive your success.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            {products.map((product, index) => (
              <motion.div
                key={product.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <div className="text-3xl text-brand-rose mb-4">
                  {product.icon}
                </div>
                <h2 className="text-2xl font-bold text-brand-burgundy mb-3">
                  {product.title}
                </h2>
                <p className="text-gray-600 mb-6">
                  {product.description}
                </p>
                <ul className="space-y-3">
                  {product.features.map((feature, i) => (
                    <li key={i} className="flex items-center text-gray-700">
                      <span className="text-brand-rose mr-2">•</span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
