// src/components/Footer.jsx

import React from 'react';
import { FiGithub, FiLinkedin, FiMail } from 'react-icons/fi';
import { motion } from 'framer-motion';

const footerLinks = {
  product: [
    { name: 'Features', href: '/product' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'Use Cases', href: '/use-cases' },
    // { name: 'API Docs', href: '/docs' }
  ],
  company: [
    { name: 'About', href: '/about' },
    // { name: 'Blog', href: '/blog' },
    // { name: 'Careers', href: '/careers' },
    // { name: 'Contact', href: '/contact' }
  ],
  resources: [
    { name: 'API Docs', href: 'https://docs.fiscusflows.com/' },
    // { name: 'Integration Guide', href: '/guides' },
    // { name: 'Community', href: '/community' },
    // { name: 'Status', href: '/status' }
  ],
  legal: [
    { name: 'Privacy', href: 'https://fiscusflows.com/privacy-policy' },
    { name: 'Terms', href: 'https://fiscusflows.com/terms-of-service' },
    // { name: 'Cookie Policy', href: '/cookies' },
    // { name: 'GDPR', href: '/gdpr' }
  ]
};

const socialLinks = [
  { icon: <FiGithub />, href: 'https://github.com/fiscus-flows' },
  { icon: <FiLinkedin />, href: 'https://www.linkedin.com/company/fiscus-flows/' },
  { icon: <FiMail />, href: 'mailto:support@fiscusflows.com' }
];

const Footer = () => {
  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="container mx-auto max-w-screen-xl px-6 py-12">
        {/* Main Footer Content */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-12">
          {Object.entries(footerLinks).map(([category, links], index) => (
            <motion.div
              key={category}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <h3 className="text-brand-burgundy font-semibold mb-4 uppercase text-sm">
                {category}
              </h3>
              <ul className="space-y-3">
                {links.map((link) => (
                  <li key={link.name}>
                    <a
                      href={link.href}
                      className="text-gray-600 hover:text-brand-rose transition-colors duration-300"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>

        {/* Bottom Bar */}
        <div className="flex flex-col md:flex-row justify-between items-center pt-8 border-t border-gray-200">
          <div className="flex items-center mb-4 md:mb-0">
            {/* <img src="/logo.svg" alt="Fîscus" className="h-8 w-auto mr-4" /> */}
            <p className="text-gray-600">
              © {new Date().getFullYear()} Fiscus Flows, Inc. All rights reserved.
            </p>
          </div>

          {/* Social Links */}
          <div className="flex space-x-6">
            {socialLinks.map((link, index) => (
              <motion.a
                key={index}
                href={link.href}
                className="text-gray-600 hover:text-brand-rose transition-colors duration-300 text-xl"
                whileHover={{ scale: 1.1 }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.icon}
              </motion.a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
