import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SEO from '../components/common/SEO';
import { FiMonitor, FiBook, FiUsers, FiShoppingBag, FiActivity, FiGrid } from 'react-icons/fi';

const categories = [
  { id: 'all', label: 'All Cases', icon: <FiGrid /> },
  { id: 'gaming', label: 'Gaming', icon: <FiMonitor /> },
  { id: 'education', label: 'Education', icon: <FiBook /> },
  { id: 'enterprise', label: 'Enterprise', icon: <FiUsers /> },
  { id: 'ecommerce', label: 'E-commerce', icon: <FiShoppingBag /> },
  { id: 'healthcare', label: 'Healthcare', icon: <FiActivity /> },
];

const useCases = [
  {
    id: 1,
    title: "Game Progress Synchronization",
    category: "gaming",
    description: "Seamlessly sync player progress across multiple gaming platforms",
    metrics: {
      setupTime: "3 minutes",
      costReduction: "75%",
      reliability: "99.9%"
    },
    features: [
      "Cross-platform data synchronization",
      "Real-time progress tracking",
      "Secure player data management",
      "Automated conflict resolution"
    ],
    image: "game-sync.jpg" // You'll need to add these images to your assets
  },
  {
    id: 2,
    title: "Student Learning Platform",
    category: "education",
    description: "Integrated learning management system with automated assignment grading",
    metrics: {
      setupTime: "5 minutes",
      costReduction: "60%",
      reliability: "99.5%"
    },
    features: [
      "Automated assignment distribution",
      "Real-time progress tracking",
      "Multi-platform accessibility",
      "Intelligent grading system"
    ],
    image: "edu-platform.jpg"
  },
  {
    id: 3,
    title: "Enterprise Data Integration",
    category: "enterprise",
    description: "Unified data platform connecting multiple department systems",
    metrics: {
      setupTime: "7 minutes",
      costReduction: "80%",
      reliability: "99.9%"
    },
    features: [
      "Cross-department data sharing",
      "Automated reporting",
      "Security compliance",
      "Real-time analytics"
    ],
    image: "enterprise-data.jpg"
  },
  {
    id: 4,
    title: "E-commerce Order Management",
    category: "ecommerce",
    description: "Automated order processing and inventory management system",
    metrics: {
      setupTime: "4 minutes",
      costReduction: "70%",
      reliability: "99.8%"
    },
    features: [
      "Multi-channel order sync",
      "Automated inventory updates",
      "Shipping integration",
      "Customer notification system"
    ],
    image: "ecom-orders.jpg"
  },
  {
    id: 5,
    title: "Patient Care Coordination",
    category: "healthcare",
    description: "Integrated healthcare service delivery platform",
    metrics: {
      setupTime: "6 minutes",
      costReduction: "65%",
      reliability: "99.99%"
    },
    features: [
      "Secure patient data sharing",
      "Appointment scheduling",
      "Treatment tracking",
      "Compliance monitoring"
    ],
    image: "healthcare-coord.jpg"
  }
];

const UseCases = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');

  const filteredCases = selectedCategory === 'all' 
    ? useCases 
    : useCases.filter(useCase => useCase.category === selectedCategory);

  return (
    <>
      <SEO 
        title="Use Cases"
        description="Explore real-world applications of Fîscus's digital workforce platform across gaming, education, enterprise, e-commerce, and healthcare sectors."
      />
      
      <section className="py-20 bg-brand-sand/10">
        <div className="container mx-auto max-w-screen-xl px-6">
          <div className="text-center mb-16">
            <h1 className="text-4xl md:text-5xl font-bold text-brand-burgundy mb-4">
              Success Stories
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Discover how businesses are transforming their operations with Fîscus's digital workforce.
            </p>
          </div>

          {/* Category Filter */}
          <div className="flex flex-wrap justify-center gap-4 mb-12">
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.id)}
                className={`flex items-center px-6 py-3 rounded-full transition-all duration-300 ${
                  selectedCategory === category.id
                    ? 'bg-brand-burgundy text-white'
                    : 'bg-white text-gray-600 hover:bg-brand-rose/10'
                }`}
              >
                <span className="mr-2">{category.icon}</span>
                {category.label}
              </button>
            ))}
          </div>

          {/* Use Cases Grid */}
          <div className="grid md:grid-cols-2 gap-8">
            <AnimatePresence mode="wait">
              {filteredCases.map((useCase) => (
                <motion.div
                  key={useCase.id}
                  layout
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <div className="p-8">
                    <h2 className="text-2xl font-bold text-brand-burgundy mb-3">
                      {useCase.title}
                    </h2>
                    <p className="text-gray-600 mb-6">
                      {useCase.description}
                    </p>

                    {/* Metrics */}
                    <div className="grid grid-cols-3 gap-4 mb-6">
                      <div className="text-center">
                        <div className="text-brand-rose font-bold">
                          {useCase.metrics.setupTime}
                        </div>
                        <div className="text-sm text-gray-500">Setup Time</div>
                      </div>
                      <div className="text-center">
                        <div className="text-brand-rose font-bold">
                          {useCase.metrics.costReduction}
                        </div>
                        <div className="text-sm text-gray-500">Cost Reduction</div>
                      </div>
                      <div className="text-center">
                        <div className="text-brand-rose font-bold">
                          {useCase.metrics.reliability}
                        </div>
                        <div className="text-sm text-gray-500">Reliability</div>
                      </div>
                    </div>

                    {/* Features */}
                    <ul className="space-y-3">
                      {useCase.features.map((feature, i) => (
                        <li key={i} className="flex items-center text-gray-700">
                          <span className="text-brand-rose mr-2">•</span>
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>
      </section>
    </>
  );
};

export default UseCases;
