import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SEO from '../components/common/SEO';
import { FiCheck, FiHelpCircle } from 'react-icons/fi';

const plans = {
	technical: [
	  {
		name: "Explore (Free)",
		description: "Discover the potential of digital worker integration with core capabilities.",
		price: "Free",
		features: [
		  "10,000 API Calls/month",
		  "10,000 Tokens/month",
		  "Unlimited Agents",
		  "Unlimited Integrations",
		  "Community Support (Forum)",
		  "Basic Logs",
		  "Shared Cloud Hosting",
		  "No SAML/SSO",
		  "$0.10/1,000 API Calls (Overages)"
		]
	  },
	  {
		name: "Build",
		description: "Empower teams to create scalable digital worker solutions with ease.",
		price: "$29/month",
		features: [
		  "100,000 API Calls/month",
		  "100,000 Tokens/month",
		  "Unlimited Agents",
		  "Unlimited Integrations",
		  "Email Support",
		  "Detailed Logs",
		  "Shared Cloud Hosting",
		  "No SAML/SSO",
		  "$0.10/1,000 API Calls (Overages)"
		]
	  },
	  {
		name: "Scale",
		description: "Expand with advanced features for managing digital worker ecosystems.",
		price: "$99/month",
		features: [
		  "1,000,000 API Calls/month",
		  "1,000,000 Tokens/month",
		  "Unlimited Agents",
		  "Unlimited Integrations",
		  "Priority Email Support",
		  "Advanced Insights",
		  "Shared Cloud Hosting",
		  "SAML/SSO Enabled",
		  "$0.08/1,000 API Calls (Overages)"
		]
	  },
	  {
		name: "Enterprise",
		description: "Tailored for enterprises seeking fully customized digital workforce solutions.",
		price: "Custom Pricing",
		features: [
		  "Custom API Call Limits",
		  "Custom Token Limits",
		  "Unlimited Agents",
		  "Unlimited Integrations",
		  "Dedicated TAM + SLAs",
		  "Fully Custom Dashboards",
		  "VPC + Self-Hosted Option",
		  "SAML/SSO Enabled",
		  "Negotiated Overages"
		]
	  }
	],
	nonTechnical: [
	  {
		name: "Explore (Free)",
		description: "Get started with digital workers to enhance everyday tasks and operations.",
		price: "Free",
		features: [
		  "1000 Executed Workflows/month",
		  "5,000 Words Processed/month",
		  "Unlimited Digital Workers",
		  "Unlimited Active Workflows",
		  "Community Support (Forum)",
		  "Basic Logs",
		  "Shared Cloud Hosting",
		  "No SAML/SSO"
		]
	  },
	  {
		name: "Build",
		description: "Develop and deploy intelligent digital workers to boost efficiency and collaboration.",
		price: "$29/month",
		features: [
		  "10,000 Executed Workflows/month",
		  "50,000 Words Processed/month",
		  "Unlimited Digital Workers",
		  "Unlimited Active Workflows",
		  "Email Support",
		  "Workflow Insights",
		  "Shared Cloud Hosting",
		  "No SAML/SSO"
		]
	  },
	  {
		name: "Scale",
		description: "Expand your digital workforce with tools for smarter operations and performance.",
		price: "$99/month",
		features: [
		  "100,000 Executed Workflows/month",
		  "500,000 Words Processed/month",
		  "Unlimited Digital Workers",
		  "Unlimited Active Workflows",
		  "Priority Email Support",
		  "Workflow + Trend Insights",
		  "Shared Cloud Hosting",
		  "SAML/SSO Enabled"
		]
	  },
	  {
		name: "Enterprise",
		description: "Custom solutions to build and manage a sophisticated digital workforce at scale.",
		price: "Custom Pricing",
		features: [
		  "Custom Executed Workflows",
		  "Custom Words Processed",
		  "Unlimited Digital Workers",
		  "Unlimited Active Workflows",
		  "Dedicated TAM + SLAs",
		  "Fully Custom Dashboards",
		  "VPC + Self-Hosted Option",
		  "SAML/SSO Enabled"
		]
	  }
	]
  };  

const faqs = [
  {
    question: "Can I change plans later?",
    answer: "Yes, you can upgrade or downgrade your plan at any time. Changes take effect immediately, and we'll prorate your billing."
  },
  {
    question: "What is included in technical vs non-technical plans?",
    answer: "It doesn't matter if you're technical or non-technical, your plan includes the same features and offerings. We just like to explain it in terms you're used to reading."
  },
  {
    question: "Do you offer a free plan?",
    answer: "Yes, we offer our Explore Plan which is free forever. No credit card required to start."
  },
  {
    question: "What kind of support do you provide?",
    answer: "All plans include email support. Professional plans add 24/7 chat support, while Enterprise plans include dedicated account management and priority support."
  }
];

const Pricing = () => {
	const [isTechnical, setIsTechnical] = useState(true);
  
	return (
	  <>
		<SEO 
		  title="Pricing"
		  description="Flexible pricing plans for your digital workforce needs. Start with a single digital worker or scale to enterprise-level automation."
		/>
  
		{/* Hero Section */}
		<section className="py-20 bg-brand-sand/10">
		  <div className="container mx-auto max-w-screen-xl px-6">
			<div className="text-center mb-16">
			  <motion.h1 
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				className="text-4xl md:text-5xl font-bold text-brand-burgundy mb-4"
			  >
				Simple, Transparent Pricing
			  </motion.h1>
			  <motion.p 
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ delay: 0.1 }}
				className="text-xl text-gray-600 max-w-2xl mx-auto"
			  >
				Choose the perfect plan for your business. Scale your digital workforce as you grow.
			  </motion.p>
  
			  {/* Plan Toggle */}
			  <motion.div 
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ delay: 0.2 }}
				className="mt-8 inline-flex items-center bg-white rounded-full p-1 shadow-lg"
			  >
				<button
				  className={`px-6 py-2 rounded-full transition-colors duration-300 ${
					!isTechnical ? 'bg-brand-burgundy text-white' : 'text-gray-600'
				  }`}
				  onClick={() => setIsTechnical(false)}
				>
				  Non-Technical
				</button>
				<button
				  className={`px-6 py-2 rounded-full transition-colors duration-300 ${
					isTechnical ? 'bg-brand-burgundy text-white' : 'text-gray-600'
				  }`}
				  onClick={() => setIsTechnical(true)}
				>
				  Technical
				</button>
			  </motion.div>
			</div>
  
			{/* Pricing Cards */}
			<div className="grid md:grid-cols-4 gap-8 mb-16">
			  {(isTechnical ? plans.technical : plans.nonTechnical).map((plan, index) => (
				<motion.div
				  key={plan.name}
				  initial={{ opacity: 0, y: 20 }}
				  animate={{ opacity: 1, y: 0 }}
				  transition={{ delay: index * 0.1 + 0.3 }}
				  className={`bg-white rounded-xl p-8 shadow-lg relative flex flex-col justify-between ${
					plan.popular ? 'border-2 border-brand-rose' : ''
				  }`}
				>
				  {plan.popular && (
					<div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					  <span className="bg-brand-rose text-white px-4 py-1 rounded-full text-sm">
						Most Popular
					  </span>
					</div>
				  )}
  
				  <div className="flex-grow">
					<h2 className="text-2xl font-bold text-brand-burgundy mb-2 text-center">
					  {plan.name}
					</h2>
					<p className="text-gray-600 mb-6 text-center">
					  {plan.description}
					</p>
					<div className="text-center mb-6">
					  {typeof plan.price === 'number' ? (
						<>
						  <span className="text-4xl font-bold text-brand-burgundy">
							${plan.price}
						  </span>
						  <span className="text-gray-600">/month</span>
						</>
					  ) : (
						<span className="text-4xl font-bold text-brand-burgundy">
						  {plan.price}
						</span>
					  )}
					</div>
				  </div>
  
				  <ul className="space-y-4 mb-8">
					{plan.features.map((feature, i) => (
					  <li key={i} className="flex items-center text-gray-700">
						<FiCheck className="text-brand-rose mr-2 flex-shrink-0" />
						{feature}
					  </li>
					))}
				  </ul>
  
				  <div>
					<Link
					  to="/contact"
					  className={`block text-center py-3 px-6 rounded-lg transition-colors duration-300 ${
						plan.popular
						  ? 'bg-brand-burgundy text-white hover:bg-brand-rose'
						  : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
					  }`}
					>
					  {plan.name === 'Enterprise' ? 'Contact Sales' : 'Get Started'}
					</Link>
				  </div>
				</motion.div>
			  ))}
			</div>
  
			{/* FAQs */}
			<div className="max-w-3xl mx-auto">
			  <h2 className="text-3xl font-bold text-brand-burgundy mb-8 text-center">
				Frequently Asked Questions
			  </h2>
			  <div className="space-y-6">
				{faqs.map((faq, index) => (
				  <motion.div
					key={index}
					initial={{ opacity: 0, y: 20 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					transition={{ delay: index * 0.1 }}
					className="bg-white p-6 rounded-xl shadow-lg"
				  >
					<div className="flex items-start">
					  <FiHelpCircle className="text-brand-rose mr-3 mt-1 flex-shrink-0" />
					  <div>
						<h3 className="font-bold text-gray-800 mb-2">{faq.question}</h3>
						<p className="text-gray-600">{faq.answer}</p>
					  </div>
					</div>
				  </motion.div>
				))}
			  </div>
			</div>
		  </div>
		</section>
  
		{/* CTA Section */}
		<section className="py-20 bg-brand-burgundy text-white">
		  <div className="container mx-auto max-w-screen-xl px-6 text-center">
			<h2 className="text-3xl font-bold mb-6">
			  Ready to Get Started?
			</h2>
			<p className="text-xl mb-8 opacity-90">
			  Try our digital workforce solution risk-free.
			</p>
			<Link
			  to="/contact"
			  className="inline-block bg-white text-brand-burgundy px-8 py-3 rounded-full hover:bg-brand-rose hover:text-white transition-colors duration-300"
			>
			  Build Your Digital Workforce
			</Link>
		  </div>
		</section>
	  </>
	);
  };
  

export default Pricing;
