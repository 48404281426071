// src/LandingPage.jsx

import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SEO from '../components/common/SEO';
import { 
  FiClock, 
  FiDollarSign, 
  FiTrendingUp, 
  FiShield,
  FiUsers,
  FiCheckCircle
} from 'react-icons/fi';

const benefits = [
  {
    icon: <FiClock />,
    title: "5-Minute Setup",
    description: "Deploy your digital workforce in minutes, not months. Our AI-powered platform handles the complex setup automatically."
  },
  {
    icon: <FiDollarSign />,
    title: "Cost Efficiency",
    description: "Reduce operational costs by up to 80% while maintaining 24/7 productivity and consistent performance."
  },
  {
    icon: <FiTrendingUp />,
    title: "Scalable Growth",
    description: "Easily scale your digital workforce up or down based on your business needs, without traditional HR constraints."
  },
  {
    icon: <FiShield />,
    title: "Enterprise Security",
    description: "Bank-grade security and compliance measures ensure your data and operations remain protected."
  }
];

const testimonials = [
  {
    quote: "Our digital workforce has transformed how we handle customer support. We're now available 24/7 with consistent response times.",
    author: "Sarah Chen",
    role: "CTO",
    company: "TechFlow Solutions"
  },
  {
    quote: "Setting up our AI integration took minutes, and the results were immediate. Our team can now focus on strategic initiatives.",
    author: "Michael Rodriguez",
    role: "Operations Director",
    company: "GlobalTech Inc"
  }
];

const features = [
  {
    title: "AI Integration Engineer",
    description: "Connect your tools and automate workflows in minutes",
    benefits: [
      "Automated API integration",
      "Real-time error handling",
      "Custom workflow creation",
      "24/7 operation"
    ]
  },
  {
    title: "AI Database Engineer",
    description: "Optimize and manage your data infrastructure automatically",
    benefits: [
      "Automated optimization",
      "Security monitoring",
      "Performance tuning",
      "Backup management"
    ]
  },
  {
    title: "AI Marketing Manager",
    description: "Drive growth with AI-powered marketing automation",
    benefits: [
      "Campaign optimization",
      "Content analysis",
      "Performance tracking",
      "Audience targeting"
    ]
  }
];

const Home = () => {
  return (
    <>
      <SEO 
        title="Digital Workforce Solutions"
        description="Transform your business with Fîscus's AI-powered digital workforce. Set up in 5 minutes, reduce costs by 80%, and operate 24/7."
      />

      {/* Hero Section */}
      <section className="py-20 bg-brand-sand/10">
        <div className="container mx-auto max-w-screen-xl px-6">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <h1 className="text-4xl md:text-5xl font-bold text-brand-burgundy mb-6">
                Your Digital Workforce,<br />
                Ready in 5 Minutes
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Transform your business operations with AI-powered digital workers that integrate seamlessly, 
                work 24/7, and deliver consistent results at a fraction of the cost.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <Link
                  to="https://airtable.com/appE5GPbKLXEkAcwG/pagxjLCXTHkpzucMM/form"
                  className="bg-brand-burgundy text-white px-8 py-3 rounded-full hover:bg-brand-rose transition-colors duration-300 text-center"
                >
                  Get Started
                </Link>
                <Link
                  to="/products"
                  className="bg-white text-brand-burgundy px-8 py-3 rounded-full hover:bg-brand-sand/20 transition-colors duration-300 text-center"
                >
                  Learn More
                </Link>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.2 }}
              className="relative"
            >
              {/* Add your hero image here */}
              <div className="aspect-square bg-brand-rose/10 rounded-full"></div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20">
        <div className="container mx-auto max-w-screen-xl px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-brand-burgundy mb-4">
              Why Choose Our Digital Workforce?
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Experience the power of AI-driven automation with immediate results and long-term scalability.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <motion.div
                key={benefit.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <div className="text-3xl text-brand-rose mb-4">
                  {benefit.icon}
                </div>
                <h3 className="text-xl font-bold text-brand-burgundy mb-2">
                  {benefit.title}
                </h3>
                <p className="text-gray-600">
                  {benefit.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 bg-brand-sand/10">
        <div className="container mx-auto max-w-screen-xl px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-brand-burgundy mb-4">
              Your Complete Digital Workforce
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              From integration to marketing, our AI-powered workforce handles it all.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-8 rounded-xl shadow-lg"
              >
                <h3 className="text-2xl font-bold text-brand-burgundy mb-4">
                  {feature.title}
                </h3>
                <p className="text-gray-600 mb-6">
                  {feature.description}
                </p>
                <ul className="space-y-3">
                  {feature.benefits.map((benefit, i) => (
                    <li key={i} className="flex items-center text-gray-700">
                      <FiCheckCircle className="text-brand-rose mr-2" />
                      {benefit}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-20">
        <div className="container mx-auto max-w-screen-xl px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-brand-burgundy mb-4">
              Trusted by Growing Businesses
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              See how our digital workforce is transforming businesses like yours.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-8 rounded-xl shadow-lg"
              >
                <p className="text-gray-600 mb-6 italic">
                  "{testimonial.quote}"
                </p>
                <div>
                  <p className="font-bold text-brand-burgundy">{testimonial.author}</p>
                  <p className="text-gray-600">{testimonial.role}</p>
                  <p className="text-gray-600">{testimonial.company}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-brand-burgundy text-white">
        <div className="container mx-auto max-w-screen-xl px-6 text-center">
          <h2 className="text-3xl font-bold mb-6">
            Ready to Transform Your Business?
          </h2>
          <p className="text-xl mb-8 opacity-90">
            Join the digital workforce revolution today and see the difference in minutes.
          </p>
          <Link
            to="https://airtable.com/appE5GPbKLXEkAcwG/pagxjLCXTHkpzucMM/form"
            className="inline-block bg-white text-brand-burgundy px-8 py-3 rounded-full hover:bg-brand-rose hover:text-white transition-colors duration-300"
          >
            Get Started Now
          </Link>
        </div>
      </section>
    </>
  );
};

export default Home;
