// src/components/Header/MobileMenu.jsx

import React from 'react';
import { Link } from 'react-router-dom';

const MobileMenu = ({ toggleMenu }) => {
  return (
    <nav className="bg-white shadow-md md:hidden">
      <div className="container mx-auto max-w-screen-xl px-6 py-4 space-y-2">
        <Link to="/products" className="block text-gray-800 hover:text-brand-rose" onClick={toggleMenu}>
          Products
        </Link>
        <Link to="/use-cases" className="block text-gray-800 hover:text-brand-rose" onClick={toggleMenu}>
          Use Cases
        </Link>
        <Link to="/pricing" className="block text-gray-800 hover:text-brand-rose" onClick={toggleMenu}>
          Pricing
        </Link>
        <Link to="/about" className="block text-gray-800 hover:text-brand-rose" onClick={toggleMenu}>
          About
        </Link>
        <Link to="https://airtable.com/appE5GPbKLXEkAcwG/pagxjLCXTHkpzucMM/form" 
          className="block bg-brand-burgundy text-white text-center px-4 py-2 rounded-full hover:bg-brand-rose transition mt-4"
          onClick={toggleMenu}
        >
          Get Started
        </Link>
      </div>
    </nav>
  );
};

export default MobileMenu;
