// src/components/Header/Header.jsx

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import MobileMenu from './MobileMenu';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="bg-white shadow-sm sticky top-0 z-50">
      <div className="container mx-auto max-w-screen-xl px-6 py-4 flex justify-between items-center">
        <Link to="/" className="text-xl font-bold text-brand-burgundy">
          Fîscus
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-8">
          <Link 
            to="/products" 
            className={`text-gray-600 hover:text-brand-rose transition-colors duration-300 ${
              location.pathname === '/products' ? 'text-brand-rose' : ''
            }`}
          >
            Products
          </Link>
          <Link 
            to="/use-cases"
            className={`text-gray-600 hover:text-brand-rose transition-colors duration-300 ${
              location.pathname === '/use-cases' ? 'text-brand-rose' : ''
            }`}
          >
            Use Cases
          </Link>
          <Link 
            to="/pricing"
            className={`text-gray-600 hover:text-brand-rose transition-colors duration-300 ${
              location.pathname === '/pricing' ? 'text-brand-rose' : ''
            }`}
          >
            Pricing
          </Link>
          <Link 
            to="/about"
            className={`text-gray-600 hover:text-brand-rose transition-colors duration-300 ${
              location.pathname === '/about' ? 'text-brand-rose' : ''
            }`}
          >
            About
          </Link>
          <Link 
            to="https://airtable.com/appE5GPbKLXEkAcwG/pagxjLCXTHkpzucMM/form"
            className="bg-brand-burgundy text-white px-4 py-2 rounded-full hover:bg-brand-rose transition-colors duration-300"
          >
            Get Started
          </Link>
        </nav>

        {/* Mobile Menu Button */}
        <button
          onClick={toggleMobileMenu}
          className="md:hidden text-gray-600 hover:text-brand-rose transition-colors duration-300"
        >
          {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>

      {/* Mobile Navigation */}
      {isMobileMenuOpen && <MobileMenu toggleMenu={toggleMobileMenu} />}
    </header>
  );
};

export default Header;
